import React, { Component } from "react"
import MobileDetect from 'mobile-detect';

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {}

  }
  mobDetect() {
    let md = new MobileDetect(window.navigator.userAgent);
   // console.log(md.os());

    switch (md.os()) {
      case 'iOS':
        window.location.replace('https://cardapio.pizzariafontanella.com.br/');
       // window.location.replace('https://apps.apple.com/br/app/pizzaria-fontanella/id1667427461');
        break;
      case 'AndroidOS':
        window.location.replace('https://cardapio.pizzariafontanella.com.br/');
       // window.location.replace('https://play.google.com/store/apps/details?id=br.com.pizzariafontanella.app');
        break;
      default:
        window.location.replace('https://cardapio.pizzariafontanella.com.br/');
        break;
    }
  }

  render() {
    return (
      <div>
        {this.mobDetect()}
      </div>
    );
  }
}

export default App;
